import React, { useState } from "react";
import { FaFacebook, FaFacebookF, FaGoogle } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import store from "../../Redux/store";
import { get_user_data, get_user_id } from "../../Redux/Slice/UserSlice";
import { get_wish_list } from "../../Redux/Slice/ProductSlice";
import { InputBox } from "../../ChildComponent/Input/Inputs";
import { toast } from "react-toastify";
import { base_url } from "../../utils/data";

export default function Login() {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const logo = base_url + "upload/logo.svg";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    if (Object.keys(errors)?.length == 0) {
      const isEmail = emailOrMobile.includes("@");
      try {
        const formData = new FormData();
        formData.append(isEmail ? "email" : "mobile", emailOrMobile);
        formData.append("password", password);
        const response = await fetch(
          base_url + "login.php",
          {
            method: "POST",
            body: formData,
          }
        );

        console.log("Response Status:", response.status);

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          setError(`Error: ${response.status} - ${errorText}`);
          return;
        }

        const data = await response.json();
        if (data.success) {
          toast.success(data?.msg)
          localStorage.setItem("user_id", data.id);
          store.dispatch(get_user_data(data));
          store.dispatch(get_wish_list(data?.id));
          // store.dispatch(get_user_id(data.id));
          navigate("/");
        } else {
          toast.error(data?.msg)
          setError(data.msg || "Login failed. Please check your credentials.");
        }
      } catch (error) {
        console.error("Catch block error:", error);
        setError("An error occurred. Please try again later.");
      }
    } else {
      setError({ ...error, ...errors });
    }
  };

  const validate = () => {
    const obj = {};
    if (!emailOrMobile || emailOrMobile?.trim()?.length == 0) {
      obj.email_err = "Please Enter Mobile or Email";
    } else if (/^\d+$/.test(emailOrMobile)) {
      if (!/^\d{10}$/.test(emailOrMobile)) {
        obj.email_err = "A valid 10-digit mobile number is required.";
      }
    } else if (!/\S+@\S+\.\S+/.test(emailOrMobile)) {
      obj.email_err = "A valid email address is required.";
    }
    if (!password || password?.trim()?.length == 0) {
      obj.password_err = "Please Enter Password";
    } else if (password?.trim()?.length <= 8) {
      obj.password_err = "Password At least 8 characters";
    }
    return obj;
  };

  return (
    <div
      // className="container-fluid d-flex align-items-center min-vh-100"
      className={`container-fluid min-vh-100 d-flex align-items-center ${
        window.innerWidth <= 760
          ? "p-1 justify-content-center"
          : "p-5 justify-content-end"
      }`}
      style={{
        backgroundImage:
          "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="col-11 col-xl-4 col-lg-5 col-md-7 col-sm-12 ">
        <div
          className={`bg-white  rounded shadow 
              ${window.innerWidth <= 760 ? "p-3" : "p-5 "}
              `}
        >
          <div className="text-center mb-3">
            <Link to="/">
              <img
                src={logo}
                className="img-fluid"
                alt="Shiloh Bridal Logo"
                style={{ maxWidth: "150px", cursor: "pointer" }}
              />
            </Link>
          </div>
          <h2 className="text-center mb-3" style={{ fontSize: "24px" }}>
            Sign In
          </h2>
          {/* {error && (
            <p
              className="text-danger text-center small mb-3"
              style={{ fontSize: "16px" }}
            >
              {error}
            </p>
          )} */}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputBox
                error={error?.email_err}
                value={emailOrMobile}
                onChange={(e) => setEmailOrMobile(e.target.value)}
                // label={"Email or Phone Number"}
                placeholder={"Email or Phone Number"}
              />
            </div>
            <div className="mb-3">
              <InputBox
              type="password"
                error={error?.password_err}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // label={"Password"}
                placeholder={"Password"}
              />
            </div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                  style={{ width: "16px", height: "16px", cursor: "pointer" }}
                />
                <label
                  className="form-check-label ms-2"
                  style={{ fontSize: "16px" }}
                  htmlFor="rememberMe"
                >
                  Remember me
                </label>
              </div>
              <Link
                to="/forgot-password-new"
                className="text-decoration-none"
                style={{ fontSize: "16px" }}
              >
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 mb-3"
              style={{
                background: "#902637",
                border: "none",
                height: "40px",
                fontSize: "20px",
              }}
            >
              Sign In
            </button>
            <div className="text-center mb-3">
              <h6 className="mb-2" style={{ fontSize: "16px" }}>
                OR
              </h6>
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  to="/"
                  className="btn mx-2 p-2 d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#8F1336",
                    width: "35px",
                    height: "35px",
                    fontSize: "16px",
                    color: "#ffffff",
                  }}
                >
                  <FaGoogle />
                </Link>
                <Link
                  to="/"
                  className="btn mx-2 p-2 d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#8F1336",
                    width: "35px",
                    height: "35px",
                    fontSize: "16px",
                    color: "#ffffff",
                  }}
                >
                  {/* <FaFacebook /> */}
                  <FaFacebookF />
                </Link>
              </div>
            </div>
            <p className="text-center mb-0" style={{ fontSize: "16px" }}>
              Don't have an account?{" "}
              <Link to="/Signup" className="text-decoration-none">
                Sign Up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
