import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const QtyInput = ({ count = 1, onChange }) => {
  
  const [qty, setQty] = useState();
  useEffect(()=>{
    setQty(count)
  },[count])
  const qtyMin = 1;
  const qtyMax = 100;

  const handleChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value) || value <= qtyMin) {
      setQty(qtyMin);
      onChange && onChange(qtyMin); 
    } else if (value >= qtyMax) {
      setQty(qtyMax);
      onChange && onChange(qtyMax); 
    } else {
      setQty(value);
      onChange && onChange(value); 
    }
  };

  const handleClick = (operator) => {
    let newQty = qty;
    if (operator === "add") {
      newQty = qty < qtyMax ? qty + 1 : qtyMax;
    } else {
      newQty = qty > qtyMin ? qty - 1 : qtyMin;
    }
    setQty(newQty);
    onChange && onChange(newQty); // Notify parent
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="input-group">
            <button
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: qty <= qtyMin ? "#888888" : "#8E1336",
                color: "white",
                border:`1px solid ${qty <= qtyMin ? "#888888" : "#8E1336"}`
                // padding:'1px',
              }}
              className="btn btn-outline"
              onClick={() => handleClick("minus")}
              disabled={qty <= qtyMin}
            >
              -
            </button>
            <input
              className="form-control text-center"
              name="product-qty"
              style={{maxWidth:'70px'}}
              min={qtyMin}
              max={qtyMax}
              value={qty}
              onChange={handleChange}
            />
            <button
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: qty >= qtyMax ? "#888888" : "#8E1336",
                border:`1px solid ${qty >= qtyMax  ? "#888888" : "#8E1336"}`,
                color: "white"
              }}
              className="btn btn-outline"
              onClick={() => handleClick("add")}
              disabled={qty >= qtyMax}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QtyInput;
