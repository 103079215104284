import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Container, Row, Col, Card } from "react-bootstrap";
import NavHeading from "../Navheading/NavHeading";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { AddAddress } from "./AddAddress";
import { CustomModal } from "../../ChildComponent/Modal/Modal";
import { base_url } from "../../utils/data";
import { toast } from "react-toastify";
import {
  get_default_address,
  get_other_address,
} from "../../Redux/Slice/UserSlice";
import store from "../../Redux/store";
import { Button } from "../../ChildComponent/Button/Button";
// import { useNavigate } from 'react-router-dom';

const Address = () => {
  const [data, setData] = useState({});
  const [type, setType] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const { user_id, default_address, other_address } = useSelector(
    (state) => state.userData
  );

  // console.log("default_address ===,", default_address);
  // console.log("other_address ===,", other_address);

  useEffect(() => {
    if (user_id) {
      store.dispatch(get_default_address(user_id));
      store.dispatch(get_other_address(user_id));
    }
  }, [user_id]);

  const handleInputChange = (key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
      [`${key}_error`]: "",
    }));
  };

  const handleSubmit = async () => {
    try {
      const body = new FormData();
      body.append("action", type == "add" ? "add_address" : "update_address");
      if (type == "edit") {
        body.append("id", data?.id);
      }
      body.append("userid", user_id);
      body.append("client_code", "SHBRD");
      body.append("first_name", data?.first_name);
      body.append("last_name", data?.last_name);
      body.append("email", data?.email);
      body.append("mobile_number", data?.mobilenumber);
      body.append("mobile", data?.mobilenumber);
      body.append("address", data?.address);
      body.append("city", data?.city);
      body.append("pin", data?.pincode);
      body.append("state", data?.state);
      body.append("country", data?.country);

      const response = await fetch(base_url + "user.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        setShowEditModal(false);
        setData({});
        store.dispatch(get_default_address(user_id));
        store.dispatch(get_other_address(user_id));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  const handleUpdateDefaultAddress = async (id) => {
    try {
      const body = new FormData();
      body.append("action", "update_defaultadd");
      body.append("userid", user_id);
      body.append("id", id);

      const response = await fetch(base_url + "user.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        store.dispatch(get_default_address(user_id));
        store.dispatch(get_other_address(user_id));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };
  const handleRemoveAddress = async (id) => {
    try {
      const body = new FormData();
      body.append("action", "address_remove");
      body.append("userid", user_id);
      body.append("id", id);

      const response = await fetch(base_url + "user.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        store.dispatch(get_default_address(user_id));
        store.dispatch(get_other_address(user_id));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  return (
    <Row className="dash_main">
      <Col
        md={12}
        className="d-flex justify-content-between align-items-center"
      >
        <h2 style={{ color: "#121212BF" }}>Saved Addresses</h2>
        <Button
          title={
            <>
              <FontAwesomeIcon icon={faPlus} className="me-2" />{" "}
              {window.innerWidth <= 760 ? "Add" : "Add New Address"}
            </>
          }
          onClick={() => {
            setShowEditModal(true);
            setType("add");
            setData({});
          }}
        ></Button>
      </Col>
      {Object.keys(default_address)?.length > 0 ? (
        <Col md={12}>
          <h2 style={{ color: "#121212BF" }}>Default Address</h2>

          <Card className="d-flex p-3 p-md-4 px-md-1 p-lg-4 shadow-sm">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <h5 style={{ color: "#121212BF" }}>
                {" "}
                {default_address?.first_name} {default_address?.last_name}
              </h5>
              <Button onClick={() => console.log("hello")} title={"Home"} />
            </div>
            <div className="mt-3">
              <h5 style={{ color: "#121212BF" }}>
                Address : {default_address?.address}
              </h5>
            </div>
            <div className="mt-3">
              <h5 style={{ color: "#121212BF" }}>
                {default_address?.city} {default_address?.pincode}
              </h5>
            </div>
            <div className="mt-3">
              <h5 style={{ color: "#121212BF" }}>
                Mobile No : {default_address?.mobilenumber}
              </h5>
            </div>
            <div
              className="d-flex justify-content-end mt-3"
              style={{ marginTop: "auto" }}
            >
              {/* <Button
                variant="outline px-5 py-2 m-1"
                style={{
                  backgroundColor: "#ffffff",
                  border: "2px solid #8E1336",
                  color: "#8E1336",
                  fontSize: "15px",
                }}
               
              >
                Edit
              </Button> */}
              <Button
                title={"Edit"}
                onClick={() => {
                  setType("edit");
                  setData(default_address);
                  setShowEditModal(true);
                }}
              />
              {/* <Button
        variant="outline px-5 py-2 m-1"
        style={{
          backgroundColor: "#ffffff",
          border: "2px solid #8E1336",
          color: "#8E1336",
          fontSize: "15px",
        }}
      >
        Remove
      </Button> */}
            </div>
          </Card>
        </Col>
      ) : (
        <Col md={12}>
          <h1 style={{ textAlign: "center", marginTop: "2rem" }}>
            Please Add Address
          </h1>
        </Col>
      )}

      {other_address?.length > 0 && (
        <Col md={12}>
          <h2 style={{ color: "#121212BF" }}>Other Address</h2>

          {other_address?.map((v, i) => (
            <Card
              key={i}
              className="d-flex p-3 p-md-4 px-md-1 p-lg-4 shadow-sm"
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h5 style={{ color: "#121212BF" }}>
                  {" "}
                  {v?.first_name} {v?.last_name}
                </h5>
                <Button title={"Home"} />
              </div>
              <div className="mt-3">
                <h5 style={{ color: "#121212BF" }}>Address : {v?.address}</h5>
              </div>
              <div className="mt-3">
                <h5 style={{ color: "#121212BF" }}>
                  {v?.city} {v?.pincode}
                </h5>
              </div>
              <div className="mt-3">
                <h5 style={{ color: "#121212BF" }}>
                  Mobile No : {v?.mobilenumber}
                </h5>
              </div>
              <div
                className="d-flex justify-content-end mt-3 gap-1"
                style={{ marginTop: "auto" }}
              >
                <Button
                  title={"Set as Default"}
                  onClick={() => {
                    handleUpdateDefaultAddress(v?.id);
                  }}
                />

                <Button
                  title={"Edit"}
                  onClick={() => {
                    setType("edit");
                    setData(v);
                    setShowEditModal(true);
                  }}
                />

                <Button
                  title={"Remove"}
                  onClick={() => {
                    handleRemoveAddress(v?.id);
                  }}
                />
              </div>
            </Card>
          ))}
        </Col>
      )}

      {showEditModal && (
        <CustomModal
          title={`${type == "add" ? "Add" : "Edit"} Delivery Address`}
          open={showEditModal}
          handleCancel={() => {
            setShowEditModal(false);
            setData({});
          }}
        >
          <AddAddress
            handleSubmit={handleSubmit}
            data={data}
            setData={setData}
            onChange={handleInputChange}
            fieldDisable={type == "add" ? [] : ["First Name", "Last Name"]}
          />
        </CustomModal>
      )}
    </Row>
  );
};

export default Address;
