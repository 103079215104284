import { toast } from "react-toastify";
import facebook from "../assets/icons/facebook.svg";
import instagram from "../assets/icons/instagram.svg";
import whatsapp from "../assets/icons/whatsapp.svg";

export const base_url = "https://shilohbridal.pmpframe.com/api/";

// export const base_url = "https://shilohbridals.com/api/";

export const updateOrderData = async (id, sid) => {
  if (id && sid) {
    try {
      const obj = {
        order_id: id,
        session_id: sid,
      };
      const body = new FormData();
      body.append("order_id", id);
      body.append("session_id", sid);
      const response = await fetch(base_url + "stripe/stripe-webhook.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const res = await response.json();
      if (res.success) {
        toast.success(res?.message);
      }
      // setShowEditModal(false);
    } catch (error) {
      console.log(error);
    }
  }
};

export const socialImages = [
  {
    src: facebook,
    alt: "Facebook",
    href: "https://www.facebook.com/",
  },
  {
    src: instagram,
    alt: "Instagram",
    href: "https://www.instagram.com/",
  },
  {
    src: whatsapp,
    alt: "WhatsApp",
    href: "https://wa.me/9177771077",
  },
];
