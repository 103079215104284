import React from "react";
import { Dropdown, InputBox } from "../../ChildComponent/Input/Inputs";
import { Button } from "../../ChildComponent/Button/Button";
import { useSelector } from "react-redux";

export const AddAddress = ({
  handleSubmit,
  data,
  onChange,
  setData,
  fieldDisable = [],
}) => {
  const { state_list } = useSelector((state) => state.otherData);

  const SubmitClick = () => {
    let errors = {};

    if (!data.first_name || data.first_name.trim() === "") {
      errors.first_name_error = "First name is required.";
    }

    if (!data.last_name || data.last_name.trim() === "") {
      errors.last_name_error = "Last name is required.";
    }

    if (!data.email) {
      errors.email_error = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email_error = "A valid email address is required.";
    }

    if (!data.mobilenumber) {
      errors.mobilenumber_error = "Mobile Number is required.";
    } else if (!/^\d{10}$/.test(data.mobilenumber)) {
      errors.mobilenumber_error = "A valid 10-digit mobile number is required.";
    }

    if (!data.address || data.address.trim() === "") {
      errors.address_error = "Address is required.";
    }

    if (!data.city || data.city.trim() === "") {
      errors.city_error = "City is required.";
    }

    if (!data.pincode || !/^\d{6}$/.test(data.pincode)) {
      errors.pincode_error = "pincode is required.";
    } else if (!/^\d{6}$/.test(data.pincode)) {
      errors.mobilenumber_error = "A valid 6-digit pincode is required.";
    }

    if (!data.country || data.country.trim() === "") {
      errors.country_error = "Country is required.";
    }

    if (!data.state || data.state.trim() === "") {
      errors.state_error = "State is required.";
    }

    setData((prevState) => ({
      ...prevState,
      ...errors,
    }));
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  return (
    <form
      //   onSubmit={handleSubmit}
      className="d-flex justify-content-center flex-column align-items-center"
    >
      <div
      className="grid-container-1 "
        // style={{
        //   display: "grid",
        //   // gridTemplateColumns: "repeat(2, 200px)",
        //    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        //   gap: "1rem",
        // }}
      >
        <InputBox
        width={"100%"}
          value={data?.first_name}
          error={data?.first_name_error}
          label={"First Name *"}
          disabled={fieldDisable?.includes("First Name")}
          placeholder={"First Name"}
          onChange={(e) => onChange("first_name", e.target.value)}
        />
        <InputBox
        width={"100%"}
          value={data?.last_name}
          error={data?.last_name_error}
          label={"Last Name *"}
          disabled={fieldDisable?.includes("Last Name")}
          placeholder={"Last Name"}
          onChange={(e) => onChange("last_name", e.target.value)}
        />
        <InputBox
        width={"100%"}
          value={data?.email}
          error={data?.email_error}
          label={"Email *"}
          placeholder={"Email"}
          onChange={(e) => onChange("email", e.target.value)}
        />
        <InputBox
        width={"100%"}
          value={data?.mobilenumber}
          error={data?.mobilenumber_error}
          label={"Mobile Number *"}
          placeholder={"Mobile Number"}
          onChange={(e) => onChange("mobilenumber", e.target.value)}
        />
        <InputBox
        width={"100%"}
          value={data?.address}
          error={data?.address_error}
          label={"Address  *"}
          placeholder={"Address "}
          onChange={(e) => onChange("address", e.target.value)}
        />
        <InputBox
        width={"100%"}
          value={data?.city}
          error={data?.city_error}
          label={"City  *"}
          placeholder={"City"}
          onChange={(e) => onChange("city", e.target.value)}
        />
        <InputBox
        width={"100%"}
          value={data?.pincode}
          error={data?.pincode_error}
          label={"Pincode  *"}
          placeholder={"Pincode"}
          onChange={(e) => onChange("pincode", e.target.value)}
        />
        <Dropdown
          value={data?.country}
          error={data?.country_error}
          label={"Country *"}
          onChange={(e) => onChange("country", e.target.value)}
        >
          <option value={""}>Country</option>
          <option value={"149"}>India</option>
        </Dropdown>
        <Dropdown
          value={data?.state}
          error={data?.state_error}
          label={"State *"}
          onChange={(e) => onChange("state", e.target.value)}
        >
          <option value={""}>State</option>
          {state_list?.map((v, i) => (
            <option key={i} value={v?.STATE_OR_UT_CODE}>
              {v?.STATE_OR_UT_NAME}
            </option>
          ))}
        </Dropdown>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        {/* <button
                type="submit"
                className="btn btn-danger btn-lg"
                // style={primaryButtonStyle}
              >
                Save Changes
              </button> */}
        <Button onClick={SubmitClick} title={"Submit"} />
      </div>
    </form>
  );
};
