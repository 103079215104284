import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";

const ProfileLayout = () => {
  return (
    <div>
      <Container fluid className="dashboard-container">
        <h1
          className="py-2"
          style={{ textAlign: "center", color: "#121212BF" }}
        >
          My Account
        </h1>
        <Row className="dashboard">
          <Col
            lg={2}
            xs={12}
            className=" px-md-1 justify-content-end align-items-end "
          >
            <Sidebar />
          </Col>
          <Col lg={10} xs={12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileLayout;
