import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { json, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReduceFunction } from "../../utils/Hook/OtherFunction";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/Banner/Banner";
import store from "../../Redux/store";
import {
  apply_promo_code,
  get_cart_list,
} from "../../Redux/Slice/ProductSlice";
import { Loading } from "../Loading";
import { base_url } from "../../utils/data";
import { get_other_address } from "../../Redux/Slice/UserSlice";
import { toast } from "react-toastify";
import emptyCart from "../../assets/emptycart.svg";
import { Button } from "../Button/Button";
const delivery = base_url + "upload/delivery.png";

const CartLayout = () => {
  const { cart_list, selected_card_product, cart_loading } = useSelector(
    (state) => state.productData
  );

  const [data, setData] = useState({});
  const [shipment_charges, setshipment_charges] = useState({});
  console.log("shipment_charges  ====", shipment_charges);

  // const couponDiscount = 10;
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  // const userId = localStorage.getItem("user_id");
  const { user_id, default_address, other_address } = useSelector(
    (state) => state.userData
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(get_cart_list(user_id));
    store.dispatch(get_other_address(user_id));
  }, [user_id]);

  useEffect(() => {
    if (user_id && Object.keys(default_address)?.length > 0) {
      handleFindPincode();
    }
  }, [default_address, user_id]);

  useEffect(() => {
    calculateTotals();
  }, [cart_list, couponDiscount, shipment_charges]);

  console.log("cart_list ===", cart_list);

  const calculateTotals = () => {
    let mrp = 0;
    let discount = 0;

    cart_list.forEach((item) => {
      const itemMRP =
        (parseFloat(item.price) +
          parseFloat(
            ReduceFunction(item?.customization, "customization_price")
          )) *
        item.count;
      const itemDiscount = parseFloat(item.discount) * item.count;
      mrp += itemMRP;
      discount += itemDiscount;
    });

    const couponAmt =
      data?.min_promo_value <= mrp ? (mrp * couponDiscount) / 100 : 0;
    const finalAmount =
      mrp -
      discount -
      couponAmt +
      (shipment_charges?.price ? shipment_charges?.price : 0);

    setData({
      ...data,
      mrp: mrp,
      discount: discount,
      couponAmt: couponAmt,
      totalAmount: finalAmount,
      ship_charge: shipment_charges?.price || 0,
      promomess:
        data?.min_promo_value <= mrp
          ? ""
          : couponCode
          ? `The minimum purchase amount required to apply the promo code - ${couponCode} is ₹${data?.min_promo_value}`
          : "",
    });
  };

  const ApplyCouponCode = () => {
    store
      .dispatch(
        apply_promo_code({
          pamt: data?.mrp,
          promocode: couponCode,
          userId: user_id,
        })
      )
      .then((val) => {
        const value = val?.payload;
        if (value?.success) {
          setCouponDiscount(value?.data?.discount);
          setData({ ...data, min_promo_value: value?.data?.min_amount });
        } else if (!value?.success) {
          // setCouponCode("");
          setData({
            ...data,
            min_promo_value: value?.minimum_amount,
            promomess: value?.message,
          });
          setCouponDiscount("");
        }
      });
  };

  const handleFindPincode = async () => {
    try {
      const obj = {
        user_id: user_id,
      };

      const response = await fetch(base_url + "orders/shipment_charges.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      const result = await response.json();
      console.log("result ===", result);

      if (result.success) {
        // setAddData({ ...addData, delivery_date: result?.data?.date });
        // navigate("/cart/mycart");
        setshipment_charges(result?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const Checkout = async () => {
    console.log("default_address ===", default_address);
    console.log("other_address ===", other_address);
    if (Object.keys(default_address)?.length == 0) {
      toast.error("Please Select Delivery Address first");
      return;
    }
    try {
      const obj = {
        userid: user_id,
      };

      const response = await fetch(`${base_url}stripe/checkout.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Correct capitalization and spelling
        },
        body: JSON.stringify(obj), // Correct capitalization of JSON.stringify
      });

      const res = await response.json();

      console.log("res ===", res);

      if (res.success) {
        toast.success(res.message); // Display success message
        // store.dispatch(get_cart_list(user_id)); // Update the cart list
        window.location.href = res.session_url;
      } else {
        toast.error(res.message); // Display error message from server
      }
    } catch (error) {
      console.error("Error in Checkout:", error);
      toast.error("An error occurred during checkout. Please try again.");
    }
  };

  return (
    <>
      <div className="container my-4">
        {cart_loading && cart_list?.length == 0 ? (
          // <div className="text-center">
          //   <div className="spinner-border text-primary" role="status">
          //     <span className="visually-hidden">Loading...</span>
          //   </div>
          // </div>
          <Loading />
        ) : (
          <>
            <h2
              className="mb-4"
              style={{
                fontSize: "1.7rem",
                fontFamily: "initial",
                fontWeight: "revert",
              }}
            >
              My Cart
            </h2>
            {cart_list?.length == 0 ? (
              <div className="d-flex w-100 gap-3 justify-content-center align-items-center flex-column">
                <img
                  src={emptyCart}
                  style={{
                    maxWidth: "300px",
                    objectFit: "cover",
                  }}
                />
                <span
                  style={{
                    color: "#121212C7",
                    fontSize: "20px",
                    textAlign:"center"
                  }}
                  
                >
                  Empty, but not for long! Find your perfect bridal look today!
                </span>
                <Button 
                onClick={()=>navigate('/wishlist')}
                title={"Add Items From Wishlist"} />
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <Outlet />
                </div>
                <div className="col-md-6">
                  {pathname.includes("/cart/address") && (
                    <div className="card my-3">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          <img
                            src={delivery}
                            alt="delivery"
                            className="me-2"
                            style={{ width: "24px" }}
                          />
                          <label className="me-2">Express Delivery</label>
                          <span className="text-muted">Know More</span>
                        </li>
                      </ul>
                    </div>
                  )}

                  <div className="card my-3">
                    <div className="card-body">
                      <h5>
                        Summary ({cart_list?.length} Item
                        {cart_list?.length > 1 ? "s" : ""})
                      </h5>
                      <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total MRP</span>
                          <span>Rs. {data?.mrp}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Discount on MRP</span>
                          <span>- Rs. {data?.discount}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>
                            Coupon Discount{" "}
                            {pathname.includes("/cart/address") && couponCode
                              ? `(${couponCode})`
                              : ""}
                          </span>
                          <span className="text-danger">
                            {data?.couponAmt > 0 ? "-" : ""} Rs.{" "}
                            {data?.couponAmt}
                          </span>
                        </li>
                        {data?.ship_charge ? (
                          <>
                            <li className="list-group-item d-flex justify-content-between">
                              <span>Shipping Fee</span>
                              <span className="text-success">
                                {" "}
                                Rs. {Number(data?.ship_charge).toFixed(1)}
                              </span>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        <li className="list-group-item d-flex justify-content-between fw-bold">
                          <span>Total Amount</span>
                          {/* <span>Rs. {totalAmount}</span> */}
                          <span>{Number(data?.totalAmount)}</span>
                        </li>
                        {data?.ship_charge ? (
                          <>
                            <li className="list-group-item d-flex justify-content-center">
                              <span style={{ textAlign: "center" }}>
                                Estimated Delivery Date :{" "}
                                <span className="text-success">
                                  {shipment_charges?.date}
                                </span>
                              </span>
                              {/* <span className="text-success">
                                {" "}
                                 {shipment_charges?.date}
                              </span> */}
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>

                  {pathname == "/cart/mycart" && (
                    <div className="card my-3">
                      <div className="card-body">
                        <p>Have a coupon? Enter your code</p>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Coupon Code"
                            value={couponCode}
                            onChange={(e) => {
                              setCouponCode(e.target.value);
                              setData({
                                ...data,
                                min_promo_value: "",
                                promomess: "",
                              });
                            }}
                          />
                          <button
                            className="btn btn"
                            onClick={() => {
                              // console.log("naveen")
                              if (couponCode?.length > 8) {
                                ApplyCouponCode();
                              }
                            }}
                            style={{
                              marginBottom: "0px",
                              backgroundColor: "#ffffff",
                              fontSize: "18px",
                              color: "#8F1336",
                              border: "2px solid #8F1336",
                              opacity: couponCode?.length > 8 ? 1 : 0.5,
                              cursor:
                                couponCode?.length > 8
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                          >
                            Apply
                          </button>
                        </div>
                        {data?.promomess && (
                          <p style={{ color: "#8F1336", marginTop: "10px" }}>
                            {data?.promomess}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      onClick={() => {
                        console.log("pathname ===", pathname);

                        if (pathname == "/cart/mycart") {
                          navigate("/cart/address");
                        } else if (pathname.includes("/cart/address")) {
                          Checkout();
                        }
                      }}
                      disabled={cart_list.length === 0}
                      style={{
                        backgroundColor: "#8F1336",
                        fontSize: "16px",
                        opacity: cart_list.length === 0 ? 0.7 : 1,
                        color: "#ffffff",
                      }}
                    >
                      {pathname == "/cart/mycart"
                        ? " Proceed"
                        : pathname.includes("/cart/address")
                        ? " Continue To Checkout"
                        : ""}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      <Banner />
    </>
  );
};

export default CartLayout;
