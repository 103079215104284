import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../store";
import { base_url } from "../../utils/data";

export const get_user_id = createAsyncThunk("user_id", async (data) => {
  return data;
});
export const get_user_data = createAsyncThunk("user_data", async (data) => {
  store.dispatch(get_user_id(data.id));
  return data;
});

export const get_default_address = createAsyncThunk(
  "get_default_address",
  async (id) => {
    const body = new FormData();
    body.append("action", "user_details");
    body.append("userid", id);
    // body.append("userid", 149);

    const response = await fetch(base_url + "user.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
export const get_other_address = createAsyncThunk(
  "get_other_address",
  async (id) => {
    const body = new FormData();
    body.append("action", "address_list");
    body.append("userid", id);

    const response = await fetch(base_url + "user.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
// order_list(page,limit)
export const get_order_list = createAsyncThunk(
  "get_order_list",
  async ({ user_id, page, limit }) => {
    const body = new FormData();
    body.append("action", "order_list");
    body.append("page", page);
    body.append("limit", limit);
    body.append("userid", user_id);

    const response = await fetch(base_url + "order.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_order = createAsyncThunk(
  "get_order",
  async ({ user_id, id }) => {
    const body = new FormData();
    body.append("action", "orderbyid");
    body.append("userid", user_id);
    body.append("id", id);

    const response = await fetch(base_url + "order.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

const initialState = {
  user_id: "",
  user_data: {},
  default_address: {},
  default_address_loading: false,
  other_address: [],
  other_address_loading: false,
  order_list: [1, 2, 3, 4],
  order_list_loading: false,
  order: {},
  order_loading: false,
};

const userDataSlice = createSlice({
  name: "userdata",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(get_user_id.fulfilled, (state, action) => {
      const data = action.payload;
      state.user_id = data;
    });
    builder.addCase(get_user_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.user_data = data;
    });

    builder.addCase(get_default_address.pending, (state, action) => {
      state.default_address_loading = true;
    });
    builder.addCase(get_default_address.rejected, (state, action) => {
      state.default_address_loading = false;
    });

    builder.addCase(get_default_address.fulfilled, (state, action) => {
      state.default_address_loading = false;
      const data = action.payload;
      if (data.success) {
        state.default_address = data?.data;
      } else {
        state.default_address = [];
      }
    });

    builder.addCase(get_other_address.pending, (state, action) => {
      state.other_address_loading = true;
    });
    builder.addCase(get_other_address.rejected, (state, action) => {
      state.other_address_loading = false;
    });

    builder.addCase(get_other_address.fulfilled, (state, action) => {
      state.other_address_loading = false;
      const data = action.payload;
      if (data.success) {
        state.other_address = data?.data;
      } else {
        state.other_address = [];
      }
    });
    builder.addCase(get_order_list.pending, (state, action) => {
      state.order_list_loading = true;
    });
    builder.addCase(get_order_list.rejected, (state, action) => {
      state.order_list_loading = false;
    });

    builder.addCase(get_order_list.fulfilled, (state, action) => {
      state.order_list_loading = false;
      const data = action.payload;
      if (data.success) {
        state.order_list = data?.data;
      } else {
        state.order_list = [];
      }
    });

    builder.addCase(get_order.pending, (state, action) => {
      state.order_loading = true;
    });
    builder.addCase(get_order.rejected, (state, action) => {
      state.order_loading = false;
    });

    builder.addCase(get_order.fulfilled, (state, action) => {
      state.order_loading = false;
      const data = action.payload;
      console.log("data ===",data);
      
      if (data.success) {
        state.order = data?.data;
      } else {
        state.order = [];
      }
    });
  },
});

export default userDataSlice.reducer;
