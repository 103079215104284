import React, { useState, useRef } from "react";
import { ArrowLeft, Eye, EyeOff } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { InputBox } from "../../ChildComponent/Input/Inputs";
import { base_url } from "../../utils/data";

const Password = () => {
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState("");
  const [passwordError, setPasswordError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [code, setCode] = useState(["", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const validateInput = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[\d\s-]{10,}$/;

    if (!value.trim()) {
      return "This field is required";
    }
    if (!emailPattern.test(value) && !phonePattern.test(value)) {
      return "Please enter a valid email or phone number";
    }
    return "";
  };

  const handleChange = (index, value) => {
    if (value.match(/^[0-9]$/)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 3 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newCode = [...code];

      if (code[index]) {
        newCode[index] = "";
        setCode(newCode);
      } else if (index > 0) {
        newCode[index - 1] = "";
        setCode(newCode);
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleResendCode = async () => {
    try {
      setResendDisabled(true);
      setResendTimer(30);

      const formData = new FormData();
      formData.append("email_mobile", input);
      formData.append("action", "online");

      const response = await fetch(
        base_url + "forgot_password.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || "Failed to resend code. Please try again.");
      }

      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      setError("Failed to resend code. Please try again.");
      setResendDisabled(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateInput(input);
    setError(validationError);

    if (!validationError) {
      try {
        const formData = new FormData();
        formData.append("email_mobile", input);
        formData.append("action", "online");

        const response = await fetch(
          base_url + "forgot_password.php",
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        if (data?.success) {
          setIsSubmitted(true);
        } else {
          setError(data.message || "An error occurred. Please try again.");
        }
      } catch (err) {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const validatePasswords = () => {
    const obj = {};
    if (!newPassword) {
      obj.new_pass_err = "New password is required";
    } else if (newPassword?.length < 8) {
      obj.new_pass_err = "Password should be at least 8 characters long";
    }
    if (!confirmPassword) {
      obj.conf_pass_err = "Confirm password is required";
    } else if (newPassword !== confirmPassword) {
      obj.conf_pass_err = "Passwords do not match";
    }
    setPasswordError({ ...passwordError, ...obj });
    return Object.keys(obj)?.length == 0;
  };

  const verifyOtp = async (otp) => {
    try {
      const formData = new FormData();
      formData.append("email_mobile", input);
      formData.append("otp", otp);
      formData.append("action", "verify_otp");

      const response = await fetch(
        base_url + "update_password.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (data.success) {
        setIsOtpVerified(true);
        setSubmitError("");
        return true;
      } else {
        setSubmitError(data.message || "Invalid OTP. Please try again.");
        return false;
      }
    } catch (err) {
      setSubmitError("Failed to verify OTP. Please try again.");
      return false;
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setSubmitError("");
    setPasswordError("");

    const otp = code.join("");
    if (otp.length !== 4) {
      setSubmitError("Please enter the complete 4-digit code");
      return;
    }

    if (!isOtpVerified) {
      const otpVerified = await verifyOtp(otp);
      if (!otpVerified) return;
    } else {
      if (!validatePasswords()) return;

      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("email_mobile", input);
        formData.append("otp", otp);
        formData.append("action", "update_password");
        formData.append("new_password", newPassword);
        formData.append("confirm_password", confirmPassword);

        const response = await fetch(
          base_url + "update_password.php",
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        if (data.success) {
          navigate("/signin");
        } else {
          setSubmitError(
            data.message || "Failed to update password. Please try again."
          );
        }
      } catch (err) {
        setSubmitError("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isSubmitted) {
    return (
      <div
        className="container-fluid d-flex align-items-center min-vh-100"
        style={{
          backgroundImage:
            "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card mt-5">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <ArrowLeft
                      onClick={() => navigate(-1)}
                      className="me-2"
                      size={28}
                    />
                    <h2 className="mb-0 fs-3">
                      {isOtpVerified ? "Reset Password" : "Submit Your Code"}
                    </h2>
                  </div>

                  {!isOtpVerified && (
                    <p className="text-muted mb-4 fs-6">
                      Please Enter The 4-Digit Code Sent To {input}
                    </p>
                  )}

                  <form onSubmit={handleVerificationSubmit}>
                    {!isOtpVerified && (
                      <>
                        <div className="d-flex gap-3 mb-2 justify-content-center ">
                          {code.map((digit, index) => (
                            <input
                              key={index}
                              ref={inputRefs[index]}
                              type="text"
                              maxLength={1}
                              value={digit}
                              onChange={(e) => {
                                handleChange(index, e.target.value);
                                setSubmitError("");
                              }}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              className="form-control text-center"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                fontSize: "1.5rem",
                              }}
                            />
                          ))}
                        </div>
                        {submitError && (
                          <p
                            style={{
                              fontSize: "14px",
                              color: "red",
                              marginBottom: "0",
                              textAlign: "center",
                            }}
                          >
                            {submitError}
                          </p>
                        )}

                        <button
                          type="button"
                          onClick={handleResendCode}
                          disabled={resendDisabled}
                          className="btn text-secondary fs-6 p-0 mb-3 border-0"
                          style={{ background: "none" }}
                        >
                          {resendTimer > 0
                            ? `Resend Code (${resendTimer}s)`
                            : "Resend Code"}
                        </button>
                      </>
                    )}

                    {/* {submitError && (
                      <div className="alert alert-danger py-2 mb-3">
                        {submitError}
                      </div>
                    )} */}

                    {isOtpVerified && (
                      <>
                        {/* <div className="mb-3">
                          <label
                            htmlFor="newPassword"
                            className="form-label fw-medium"
                          >
                            New Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              className={`form-control ${
                                passwordError ? "is-invalid" : ""
                              }`}
                              id="newPassword"
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                setPasswordError("");
                              }}
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              {showNewPassword ? (
                                <EyeOff size={20} />
                              ) : (
                                <Eye size={20} />
                              )}
                            </button>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label fw-medium"
                          >
                            Confirm Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className={`form-control ${
                                passwordError ? "is-invalid" : ""
                              }`}
                              id="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setPasswordError("");
                              }}
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <EyeOff size={20} />
                              ) : (
                                <Eye size={20} />
                              )}
                            </button>
                          </div>
                          {passwordError && (
                            <div className="invalid-feedback d-block">
                              {passwordError}
                            </div>
                          )}
                        </div> */}
                        <div className="d-felx flex-column gap-3 pb-3">
                          <InputBox
                            label={"New Password"}
                            placeholder={"New Password"}
                            pass
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                              setPasswordError("");
                            }}
                            error={passwordError?.new_pass_err}
                          />
                          <InputBox
                            label={"Confirm Password"}
                            placeholder={"Confirm Password"}
                            pass
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setPasswordError("");
                            }}
                            error={passwordError?.conf_pass_err}
                          />
                        </div>
                      </>
                    )}

                    <button
                      type="submit"
                      className="btn w-100 py-2 mb-3 fs-5 text-white"
                      style={{
                        backgroundColor: "#902637",
                        borderColor: "#902637",
                      }}
                      disabled={isLoading}
                    >
                      {isLoading
                        ? "Updating..."
                        : isOtpVerified
                        ? "Update Password"
                        : "Verify OTP"}
                    </button>

                    {!isOtpVerified && (
                      <button
                        type="button"
                        onClick={() => {
                          setIsSubmitted(false);
                          setInput("");
                          setIsOtpVerified(false);
                          setError("");
                          setSubmitError("");
                        }}
                        className="btn text-secondary fs-6 p-0 border-0"
                        style={{ background: "none" }}
                      >
                        Change Email Or Phone Number
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="container-fluid d-flex align-items-center min-vh-100"
      style={{
        backgroundImage:
          "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card mt-5">
              <div className="card-body">
                <div className="mb-4">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => navigate("/signin")}
                    style={{ cursor: "pointer" }}
                  >
                    <ArrowLeft size={28} className="me-2" />
                    <span className="fs-5">Forgot Password</span>
                  </div>
                </div>

                <p className="text-muted mb-4">
                  Please Enter Your Email Address or Mobile number To Receive A
                  Verification Code
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <InputBox
                      value={input}
                      onChange={(e) => {
                        setInput(e.target.value?.trim());
                        if (error) setError("");
                      }}
                      label={"Email or Phone Number"}
                      placeholder={"Email or Phone Number"}
                      error={error}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-danger btn-sm w-100 py-2 fs-6"
                    style={{
                      backgroundColor: "#902637",
                      borderColor: "#902637",
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
