import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Form,
  InputGroup,
} from "react-bootstrap";
import NavHeading from "../Navheading/NavHeading";
import NavBar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch, faTruck } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import store from "../../Redux/store";
import { get_order_list } from "../../Redux/Slice/UserSlice";
import { Loading } from "../../ChildComponent/Loading";
import { FaEye, FaFilePdf, FaStar } from "react-icons/fa";
import { IoIosRepeat } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { base_url } from "../../utils/data";

export default function Orders() {
  const [orders, setOrders] = useState([]); // State to hold orders
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();
  const { user_id, order_list, order_list_loading } = useSelector(
    (state) => state.userData
  );

  console.log("order_list ====", order_list);

  useEffect(() => {
    if (user_id) {
      store.dispatch(get_order_list({ user_id, page, limit }));
    }
  }, [user_id]);

  return (
    <Row className="order_main">
      {/* All Orders Heading, Filter Button, and Search Input */}
      {/* <Col
        md={12}
        className="d-flex justify-content-between align-items-center"
      >
        <h2 style={{ color: "#121212BF" }}>All Orders</h2>
        <div className="d-flex align-items-center">
          <InputGroup
            style={{ width: "276px", marginRight: "10px", marginTop: "0px" }}
          >
            <InputGroup.Text
              style={{ backgroundColor: "#ffffff", color: "#8E1336" }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search Orders"
              style={{ height: "43px" }}
              value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)} // Update search term
            />
          </InputGroup>
          <Button
            variant="outline px-5 py-2"
            style={{
              backgroundColor: "#ffffff",
              border: "2px solid #8E1336",
              color: "#8E1336",
              fontSize: "15px",
              marginBottom: "0px",
            }}
            // onClick={() => fetchOrders(searchTerm)} // Refetch orders on button click
          >
            <FontAwesomeIcon icon={faFilter} className="me-2" /> Filter
          </Button>
        </div>
      </Col> */}

      {/* Order Cards */}
      {order_list_loading && order_list?.length == 0 ? (
        <Loading />
      ) : order_list?.length > 0 ? (
        order_list.map((order, i) => (
          <Col md={12} key={i}>
            <Card
              onClick={() => navigate(`/order-details/${order?.Rpayorderid}`)}
              style={{ cursor: "pointer" }}
              className="order_card"
            >
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={base_url + "upload/product/9022-VNXl3Vt6gE.png"}
                  alt=""
                  style={{
                    height: "auto",
                    width: "150px",
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column ">
                  <div className="">Order</div>
                  <div className="fw-bold">#{order?.Rpayorderid}</div>
                </div>
                <div className="d-flex flex-column ">
                  <div className="">Date</div>
                  <div className="fw-bold">
                    {moment(order?.datetime).format("DD-MMM-YYYY hh:mm A")}
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <div className="">Order Status</div>
                  <div className="fw-bold">In Process</div>
                </div>
                <div className="d-flex flex-column ">
                  <div className="">Payment Mode</div>
                  <div className="fw-bold">Card</div>
                </div>
              </div>
            </Card>
          </Col>
        ))
      ) : (
        <Col md={12} className="text-center py-4">
          <h4 style={{ color: "#121212BF" }}>No data found</h4>
        </Col>
      )}
    </Row>
  );
}
