import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported
import { base_url } from "../../utils/data";

export default function Background() {
  const [banner, setBanner] = useState(null); // State to hold the image URL
  const [error, setError] = useState(null); // State to hold any error message

  useEffect(() => {
    const fetchBannerImage = async () => {
      try {
        const formData = new FormData();
        formData.append("action", "banner_list"); // Add the action for banner

        const response = await fetch(
          base_url + "gallery.php",
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // Parse the response as JSON
        if (data.success && data.data && data.data.length > 0) {
          // Extract the URL of the banner image from the response
          const bannerUrl = data.data[0].url;
          setBanner(bannerUrl); // Set the banner image URL into the state
        } else {
          throw new Error("Banner image not found");
        }
      } catch (error) {
        console.error("Error fetching banner image:", error);
        setError("Failed to fetch banner image");
      }
    };

    fetchBannerImage(); // Fetch the banner image when the component mounts
  }, []);


  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 p-0">
          {error ? (
            <p className="text-danger">{error}</p>
          ) : (
            banner && (
              <img src={banner} alt="Banner" className="img-fluid w-100" />
            )
          )}
        </div>
      </div>
    </div>
  );
}
