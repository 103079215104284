import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { base_url, updateOrderData } from "../../utils/data";
import { toast } from "react-toastify";
import store from "../../Redux/store";
import { useSelector } from "react-redux";
import { get_order } from "../../Redux/Slice/UserSlice";
import moment from "moment";

const OrderDetails = () => {
  const [products, setProducts] = useState([1, 2, 3]);
  const navigate = useNavigate();
  const { id, sid } = useParams();
  const { user_id, order, order_loading } = useSelector(
    (state) => state.userData
  );

  console.log("order =====", order);
  console.log("id =====", id);
  console.log("sid =====", sid);

  useEffect(() => {
    if (sid && id) {
      updateOrderData(id, sid);
      if (user_id) {
        store.dispatch(get_order({ id, user_id }));
      }
    }
  }, [sid]);

  useEffect(() => {
    if (user_id) {
      store.dispatch(get_order({ id, user_id }));
    }
  }, [user_id]);

  return (
    <div className="container-fluid order_details">
      <div>
        <div style={{ flex: 2 }} className="d-flex flex-column gap-3">
          {order?.products?.map((v, i) => (
            <Card
              onClick={() => navigate(`/product/${v?.id}`)}
              key={i}
              className="d-flex flex-row justify-content-start gap-5  p-4 px-3 shadow-sm"
            >
              <img
                src={v?.image}
                alt=""
                style={{
                  height: "auto",
                  width: "100px",
                  objectFit: "cover",
                  borderRadius: "6px",
                }}
              />
              <div className="d-flex gap-2 flex-column">
                <div>#{v?.itemcode}</div>
                <div className="fw-bold">
                  {/* naveen test naveen test naveen test */}
                  {v?.name} {v?.nameitem1} {v?.nameitem2}
                </div>
                <div>Rs. 2000</div>
                <div>Quantity : 10</div>
              </div>
            </Card>
          ))}
        </div>
        <Card
          style={{ flex: 1 }}
          className="d-flex flex-column gap-5 justify-content-start p-3"
        >
          <div className="d-flex flex-column gap-1 justify-content-start">
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Order#</div>
              <div style={{ flex: 1 }}>{order?.Rpayorderid}</div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Date</div>
              <div style={{ flex: 1 }}>
                {" "}
                {moment(order?.datetime).format("DD-MMM-YYYY hh:mm A")}
              </div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Payment Mode</div>
              <div style={{ flex: 1 }}>Card</div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Sub Total</div>
              <div style={{ flex: 1 }}>Rs. {order?.price}</div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Total Sum </div>
              <div style={{ flex: 1 }}>Rs. {order?.price}</div>
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <h3>Shipping Traiking</h3>
            <p>Traiking : In Process</p>
          </div>
          <div className="d-flex flex-column gap-1">
            <h3>Shipping Address</h3>
            <p className="mb-0">Test</p>
            <p className="mb-0">Naveen@veramasa.com</p>
            <p className="mb-0">7692802631</p>
            <p className="mb-0">Opp Khajuriya bina tah hatpipliya </p>
            <p className="mb-0">Indore</p>
            <p className="mb-0">Madhya Pradesh - 500015</p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default OrderDetails;
