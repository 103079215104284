import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import store from "../../Redux/store";
import {
  get_default_address,
  get_other_address,
} from "../../Redux/Slice/UserSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { base_url, updateOrderData } from "../../utils/data";
import { CustomModal } from "../../ChildComponent/Modal/Modal";
import { Loading } from "../../ChildComponent/Loading";
import { AddAddress } from "../Address/AddAddress";
import { Button } from "../../ChildComponent/Button/Button";
import { Col } from "react-bootstrap";
import { Tooltip } from "antd";

const CheckoutSummary = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [type, setType] = useState("");
  const [editFormData, setEditFormData] = useState({});
  const {
    user_id,
    default_address,
    default_address_loading,
    other_address,
    other_address_loading,
  } = useSelector((state) => state.userData);
  const { id, sid } = useParams();

  console.log("id =====", id);
  console.log("sid =====", sid);

  useEffect(() => {
    if (sid && id) {
      updateOrderData(id, sid);
    }
  }, [sid, id]);

  useEffect(() => {
    if (user_id) {
      store.dispatch(get_default_address(user_id));
      store.dispatch(get_other_address(user_id));
    }
  }, [user_id]);

  const navigate = useNavigate();

  const handleEditAddress = (v) => {
    setShowEditModal(true);
    setEditFormData(v);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleInputChange = (key, value) => {
    setEditFormData((prev) => ({
      ...prev,
      [key]: value,
      [`${key}_error`]: "",
    }));
  };

  const handleSubmitEdit = async () => {
    try {
      const body = new FormData();
      body.append("action", type == "add" ? "add_address" : "update_address");
      if (type == "edit") {
        body.append("id", editFormData?.id);
      } else {
        body.append("first_name", editFormData?.first_name);
        body.append("last_name", editFormData?.last_name);
      }
      body.append("userid", user_id);
      body.append("email", editFormData?.email);
      body.append("mobile", editFormData?.mobilenumber);
      body.append("mobile_number", editFormData?.mobilenumber);
      body.append("address", editFormData?.address);
      body.append("city", editFormData?.city);
      body.append("pin", editFormData?.pincode);
      body.append("state", editFormData?.state);
      body.append("country", editFormData?.country);

      const response = await fetch(base_url + "user.php", {
        method: "POST",
        body: body,
      });
      const data = await response.json();
      if (data.success) {
        setShowEditModal(false);
        store.dispatch(get_default_address(user_id));
        store.dispatch(get_other_address(user_id));
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  const handleUpdateDefaultAddress = async (id) => {
    try {
      const body = new FormData();
      body.append("action", "update_defaultadd");
      body.append("userid", user_id);
      body.append("id", id);

      const response = await fetch(base_url + "user.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        store.dispatch(get_default_address(user_id));
        store.dispatch(get_other_address(user_id));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };
  const handleRemoveAddress = async (id) => {
    try {
      const body = new FormData();
      body.append("action", "address_remove");
      body.append("userid", user_id);
      body.append("id", id);

      const response = await fetch(base_url + "user.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        store.dispatch(get_default_address(user_id));
        store.dispatch(get_other_address(user_id));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between">
        <h2 className="h3 mb-4">Select Delivery Address</h2>
        <Button
          title={window.innerWidth <= 760 ? "Add" : "Add New Address"}
          onClick={() => {
            setShowEditModal(true);
            setEditFormData("");
            setType("add");
          }}
        />
      </div>

      <div className="col">
        {/* Left Column - Address */}
        {Object.keys(default_address)?.length > 0 ? (
          <div className="col-md-12" style={{ flex: 1 }}>
            <h4>Default Address</h4>
            {default_address_loading ? (
              <Loading />
            ) : default_address ? (
              <div className="card mb-4">
                <div className="card-body">
                  <div className="d-flex gap-3">
                    <div className="form-check mt-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={true}
                        readOnly
                        style={{
                          "--bs-form-check-bg": "#fff",
                          "--bs-form-check-border-color": "#8F1336",
                          "--bs-form-check-checked-bg": "#8F1336",
                          "--bs-form-check-checked-border-color": "#8F1336",
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h3 className="h5 mb-3">
                        {default_address?.first_name}{" "}
                        {default_address?.last_name}
                      </h3>
                      <p className="text-muted mb-2">
                        {default_address?.address}
                      </p>
                      <p className="text-muted mb-2">
                        {default_address?.city} {default_address?.pincode}
                      </p>
                      <p className="text-muted mb-3">
                        Mobile: {default_address?.mobilenumber}
                      </p>
                      <div className="d-flex gap-2">
                        {/* <button
                      onClick={handleRemoveAddress}
                      className="btn btn-outline-danger me-2"
                      style={buttonStyle}
                    >
                      Remove
                    </button> */}
                        <button
                          onClick={() => {
                            handleEditAddress(default_address);
                          }}
                          className="btn btn-outline-danger"
                          style={buttonStyle}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card mb-4">
                <div className="card-body text-center text-muted">
                  <p>No delivery address selected</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Col md={12}>
            <h1 style={{ textAlign: "center", marginTop: "2rem" }}>
              Please Add Address
            </h1>
          </Col>
        )}
        {other_address?.length > 0 && (
          <div className="col-md-12" style={{ flex: 1 }}>
            <h4>Other Address</h4>
            {other_address_loading ? (
              <Loading />
            ) : other_address ? (
              <>
                {other_address?.map((v, i) => (
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex gap-3">
                        <div className="form-check mt-2">
                          <Tooltip title="Set as Deafault Address">
                            <input
                              type="radio"
                              className="form-check-input"
                              // checked={true}
                              onChange={() => {
                                handleUpdateDefaultAddress(v?.id);
                              }}
                              readOnly
                              style={{
                                "--bs-form-check-bg": "#fff",
                                "--bs-form-check-border-color": "#8F1336",
                                "--bs-form-check-checked-bg": "#8F1336",
                                "--bs-form-check-checked-border-color":
                                  "#8F1336",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <div className="flex-grow-1">
                          <h3 className="h5 mb-3">
                            {v?.first_name} {v?.last_name}
                          </h3>
                          <p className="text-muted mb-2">{v?.address}</p>
                          <p className="text-muted mb-2">
                            {v?.city} {v?.pincode}
                          </p>
                          <p className="text-muted mb-3">
                            Mobile: {v?.mobilenumber}
                          </p>
                          <div className="d-flex gap-2">
                            <button
                              onClick={() => handleRemoveAddress(v?.id)}
                              className="btn btn-outline-danger me-2"
                              style={buttonStyle}
                            >
                              Remove
                            </button>
                            <button
                              onClick={() => {
                                handleEditAddress(v);
                              }}
                              className="btn btn-outline-danger"
                              style={buttonStyle}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="card mb-4">
                <div className="card-body text-center text-muted">
                  <p>No delivery address selected</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Edit Address Modal */}
      {showEditModal && (
        <CustomModal
          title={`${type == "add" ? "Add" : "Edit"} Delivery Address`}
          open={showEditModal}
          handleCancel={handleCloseModal}
        >
          <AddAddress
            handleSubmit={handleSubmitEdit}
            data={editFormData}
            setData={setEditFormData}
            onChange={handleInputChange}
            fieldDisable={type == "add" ? [] : ["First Name", "Last Name"]}
          />
        </CustomModal>
      )}
    </div>
  );
};

const buttonStyle = {
  "--bs-btn-color": "#8F1336",
  "--bs-btn-border-color": "#8F1336",
  "--bs-btn-hover-color": "#fff",
  "--bs-btn-hover-bg": "#8F1336",
  "--bs-btn-hover-border-color": "#8F1336",
  "--bs-btn-active-bg": "#8F1336",
  "--bs-btn-active-border-color": "#8F1336",
};

const primaryButtonStyle = {
  backgroundColor: "#8F1336",
  borderColor: "#8F1336",
  "--bs-btn-hover-bg": "#7a102e",
  "--bs-btn-hover-border-color": "#7a102e",
};

export default CheckoutSummary;
