import React from "react";
import NavHeading from "../components/Navheading/NavHeading";
import NavBar from "../components/Navbar/Navbar";
import ContactHeading from "../components/ContactHeading/ContactHeading";
import Background from "../components/Background/Background";
import CollectionData from "../components/Collections/Collection";
import ShopByCategories from "../components/ShopByCategories/ShopByCategories";
import Customer from "../components/Customers/Customer";
import Blog from "../components/Blog/Blog";
import Newsletter from "../components/Newsletter/Newsletter";

import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";

//
//

export default function Home() {
  return (
    <div style={{ maxWidth: "100%" }}>
      <ContactHeading />
      <Background />
      <CollectionData />
      <ShopByCategories />
      {/* <Customer /> */}
      <Blog />
      <Newsletter />
      <Gallery />
      <Footer />
      <Banner />
    </div>
  );
}
